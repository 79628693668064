import React from "react";
import { Images, Tags } from "../../../../components/atoms/index";
import { useState, useEffect } from "react";
import { formatRupiah } from "../../../../utils/Helper";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { Col, Divider, Row } from "antd";

export default function OfficeDetail(props) {
  //icon marker
  L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  });

  const {
    childData,
    DataRef,
    DescriptionRef,
    OfficeDetailRef,
    PriceRef,
    PriceExternalRef,
    OfficeReviewRef,
  } = props;
  const [listImage, setListImage] = useState([]);
  useEffect(() => {
    getListImage();
  }, []);
  function getListImage() {
    const officeImages = childData.office_catalogue
      ? childData.office_catalogue.map((item) => item.url)
      : [];
    const images = [
      ...officeImages,
      childData.image_1,
      childData.image_2,
      childData.image_3,
      childData.image_4,
      childData.image_5,
    ];
    const availableImages = [];
    images.forEach((item) => {
      if (item) {
        availableImages.push(item);
      }
    });
    setListImage(availableImages);
  }

  return (
    <div className="pb-8 pt-2">
      <div>
        <h2 className="text-lg font-bold text-baseColor mb-2">
          Office Information
        </h2>
        <Row gutter={16}>
          <Col span={12} className="space-y-2">
            <div>
              <div className="text-secondary">Office Name</div>
              <div>{childData.name}</div>
            </div>
            <div>
              <div className="text-secondary">Office Category</div>
              <div>{childData.office_category_name}</div>
            </div>
            <div>
              <div className="text-secondary">Office Region</div>
              <div>{childData.region_name}</div>
            </div>
          </Col>
          <Col span={12} className="space-y-2">
            <div>
              <div className="text-secondary">Office Description</div>
              <div>{childData.description || "-"}</div>
            </div>
            <div>
              <div className="text-secondary">Status</div>
              <Tags
                title={childData.status ? "Active" : "Not Active"}
                color={childData.status ? "green" : "red"}
              />
              <div>
                <div className="text-secondary">Odoo Office Id</div>
                <div>{childData.odoo_id || "-"}</div>
              </div>
            </div>
          </Col>
        </Row>
        <Divider />

        <div className="text-lg font-bold text-baseColor mb-2 mt-2">
          Office Pictures
        </div>

        <div className="flex gap-4 mb-4">
          {listImage?.map((item, index) => (
            <Images src={item} key={index} width={70} height={70} />
          ))}
          {listImage?.length < 1 && <div>No pictures</div>}
        </div>
        <Divider />

        <div className="mb-4">
          <div className="text-lg font-bold text-baseColor mb-2 mt-2">
            Location
          </div>
          <div className="mb-6" style={{ height: "300px", width: "100%" }}>
            <MapContainer
              center={[childData.lat, childData.lng]}
              zoom={90}
              scrollWheelZoom={true}
              style={{ height: "100%", width: "100%" }}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              <Marker position={[childData.lat, childData.lng]}>
                <Popup>{childData.office_details?.address}</Popup>
              </Marker>
            </MapContainer>
          </div>
        </div>
        <Divider />
        <h2
          ref={OfficeDetailRef}
          className="text-lg font-bold text-baseColor mb-2"
        >
          Office Detail
        </h2>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <div className="text-secondary">Service Name</div>
            <div>{childData.office_details.service_name || "-"}</div>
          </div>
          <div>
            <div className="text-secondary">Phone Number</div>
            <div>{childData.office_details.location || "-"}</div>
          </div>
          <div>
            <div className="text-secondary">Address</div>
            <div>{childData.office_details.address || "-"}</div>
          </div>
          <div>
            <div className="text-secondary">Capacity Chair</div>
            <div>{formatRupiah(childData.office_details.capacity_chair)}</div>
          </div>
          <div>
            <div className="text-secondary">Capacity Desk</div>
            <div>{formatRupiah(childData.office_details.capacity_desk)}</div>
          </div>
          <div>
            <div className="text-secondary">Capacity Person</div>
            <div>{formatRupiah(childData.office_details.capacity_person)}</div>
          </div>
        </div>
        <Divider />
        <h2
          ref={PriceRef}
          className="mt-6 text-lg font-semibold text-primary mb-2"
        >
          Price (User with Accounts)
        </h2>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <div className="text-secondary">Price/hour</div>
            <div>Rp. {formatRupiah(childData.price.price_per_hour)}</div>
          </div>
          <div>
            <div className="text-secondary">Price/day</div>
            <div>Rp. {formatRupiah(childData.price.price_daily)}</div>
          </div>
          <div>
            <div className="text-secondary">Price/week</div>
            <div>Rp. {formatRupiah(childData.price.price_weekly)}</div>
          </div>
          <div>
            <div className="text-secondary">Price/month</div>
            <div>Rp. {formatRupiah(childData.price.price_monthly)}</div>
          </div>
          <div>
            <div className="text-secondary">Price/Overtime (per hour)</div>
            <div>
              Rp. {formatRupiah(childData.price.price_per_hour_overtime)}
            </div>
          </div>
        </div>
        <Divider />
        <h2
          ref={PriceExternalRef}
          className="mt-6 text-lg font-semibold text-primary mb-2"
        >
          Price (External)
        </h2>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <div className="text-secondary">Price/hour</div>
            <div>
              Rp. {formatRupiah(childData.price.price_per_hour_external)}
            </div>
          </div>
          <div>
            <div className="text-secondary">Price/day</div>
            <div>
              Rp. {formatRupiah(childData.price.price_per_day_external)}
            </div>
          </div>
          <div>
            <div className="text-secondary">Price/half day</div>
            <div>
              Rp. {formatRupiah(childData.price.price_half_day_external)}
            </div>
          </div>
          <div>
            <div className="text-secondary">Price/Overtime (per hour)</div>
            <div>
              Rp.{" "}
              {formatRupiah(childData.price.price_per_hour_overtime_external)}
            </div>
          </div>
        </div>
        <h2
          ref={OfficeReviewRef}
          className="mt-6 text-lg font-semibold text-primary mb-2"
        >
          Office Review
        </h2>

        {/* <h2 ref={TagRef} className="text-primary font-medium mt-6">
          Tag
        </h2> */}
        {/* <div className="grid grid-cols-2 gap-4">
          <div>
            <div className="text-secondary mb-1">Meta Tag Penyakit</div>
            {childData.meta_tag_disease ? (
              childData.meta_tag_disease?.map((item, index) => (
                <Tag key={index}>{item}</Tag>
              ))
            ) : (
              <div>-</div>
            )}
          </div>
          <div>
            <div className="text-secondary mb-1">Meta Tag Keyword</div>
            {childData.meta_tag_keyword ? (
              childData.meta_tag_keyword?.map((item, index) => (
                <Tag key={index}>{item}</Tag>
              ))
            ) : (
              <div>-</div>
            )}
          </div>
          <div>
            <div className="text-secondary mb-1">Meta Tag Organ</div>
            {childData.meta_tag_organ ? (
              childData.meta_tag_organ?.map((item, index) => (
                <Tag key={index}>{item}</Tag>
              ))
            ) : (
              <div>-</div>
            )}
          </div>
        </div> */}
        {/* <h2 ref={VariantRef} className="text-primary font-medium mt-6">
          Variant
        </h2>
        <Table
          dataSource={childData.product_variants}
          columns={columns}
          pagination={false}
          rowKey="id"
        /> */}
      </div>
      {/* <VariantDetail
        viewVariant={viewVariant}
        setViewVariant={setViewVariant}
        variantDetail={variantDetail}
      /> */}
    </div>
  );
}
