import React, { useState, useEffect } from "react";
import { Table, Modal, notification } from "antd";
import _ from "lodash";
import {
  Buttons,
  CustomPagination,
  Images,
  InputSearch,
  Tags,
} from "../../../components/atoms";
import {
  PlusOutlined,
  EyeFilled,
  EditFilled,
  DeleteFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";
import FormSection from "./FormSection/FormSection";
import { formatPromoType, formatPromoTypeColor } from "../../../utils/Helper";
import {
  deletePromotions,
  getPromotions,
  getPromotionsDetail,
} from "../../../services/api";
import { Link } from "react-router-dom";

const { confirm } = Modal;

export default function PromotionsList() {
  const [section, setSection] = useState("default");
  const [trigger, setTrigger] = useState(false);
  const [data, setData] = useState([]);
  // const [DiscountDetail, setDiscountDetail] = useState([]);
  const [childData, setChildData] = useState({});
  const [totalData, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({
    offset: 0,
    limit: 10,
    order: "created_at,desc",
    keyword: "",
  });

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, params]);

  const getData = () => {
    setLoading(true);
    getPromotions(params)
      .then((res) => {
        setData(res.data.data);
        setTotal(res.data.total_data);
      })
      .catch((err) => {
        throw new Error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function onPageChange(page, pageSize) {
    const offset = (page - 1) * pageSize;
    const limit = pageSize;
    setParams({ ...params, offset, limit });
  }

  const onSearch = _.debounce(function (e) {
    setParams({ ...params, keyword: e.target.value });
  }, 500);

  useEffect(() => {
    if (section === "default") {
      setChildData({});
    }
  }, [section, setSection]);

  const columns = [
    {
      title: "No",
      dataIndex: "key",
      rowScope: "row",
      render: (text, object, index) => {
        return <div>{index + 1}</div>;
      },
    },
    {
      title: "name",
      dataIndex: "name",
      width: 250,
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => (
        <div className="flex items-center gap-2">
          {/* <Images src={record.image} width={60} height={60} /> */}
          <div>{text}</div>
        </div>
      ),
    },
    {
      title: "Voucher Code Title",
      dataIndex: "voucher_code_title",
      sorter: (a, b) => a.type.localeCompare(b.type),
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Voucher Code",
      dataIndex: "voucher_code",
      sorter: (a, b) => a.type.localeCompare(b.type),
      render: (text) => (
        <Tags
          title={formatPromoType(text)}
          color={formatPromoTypeColor(text)}
        />
      ),
    },
    {
      title: "Image",
      dataIndex: "url_image",
      width: 150,
      render: (text, record) => (
        <div className="flex items-center gap-2">
          <Images src={record.url_image} width={120} height={120} />
        </div>
      ),
    },
    {
      title: "Created At",
      dataIndex: "start_date",
      sorter: (a, b) => a.type.localeCompare(b.type),
      render: (text, record) => (
        <div>{moment(record.start_date).format("L")}</div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <>
          <div className="flex items-center gap-x-1">
            <Buttons
              className="py-1 rounded-lg hover:border-primary"
              icon={<EyeFilled className="text-gray-600 hover:text-primary" />}
              onClick={() => viewData(record)}
            />
            <Buttons
              className="py-1 rounded-lg hover:border-primary"
              icon={<EditFilled className="text-gray-600 hover:text-primary" />}
              onClick={() => editData(record)}
            />
            <Buttons
              className="py-1 rounded-lg hover:border-primary"
              icon={
                <DeleteFilled className="text-gray-600 hover:text-primary" />
              }
              onClick={() => deleteData(record.id)}
            />
          </div>
        </>
      ),
    },
  ];

  const editData = (record) => {
    getPromotionsDetail(record.id)
      .then((res) => {
        setChildData(res.data.data);
        setSection("edit");
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

  const viewData = (record) => {
    getPromotionsDetail(record.id)
      .then((res) => {
        setChildData(res.data.data);
        setSection("view");
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

  const addData = () => {
    setSection("add");
  };
  const deleteData = (id) => {
    confirm({
      title: "Confirm Delete",
      content:
        "Are you sure you want to delete this data? This action cannot be undone.",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        return new Promise((resolve, reject) => {
          deletePromotions(id)
            .then((res) => {
              notification.success({
                message: "Success!",
                description: "Successfully delete data",
                placement: "topRight",
              });
              getData();
              resolve(res);
            })
            .catch((err) => {
              notification.error({
                message: "Failed!",
                description: "Failed to delete data",
                placement: "topRight",
              });
              reject(err);
            });
        });
      },
    });
  };

  return (
    <>
      {section === "default" && (
        <div className="content-section">
          <div className="breadcrumb mb-4 text-md">
            <Link to="/" className="text-gray-500">
              Dashboard
            </Link>
            <span className="mx-2">&gt;</span>
            <span>Promotions List</span>
          </div>
          <div className="header">
            <h2 className="lg:text-[28px] text-[20px]">Promotions List</h2>
            <div className="flex flex-col md:flex-row items-center md:gap-4 gap-1">
              <Buttons
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => addData()}
              >
                Add Data
              </Buttons>
            </div>
          </div>
          <div className="mb-6 md:w-80 w-32">
            <InputSearch
              onChange={onSearch}
              placeholder="Search Name Promotions"
            />
          </div>
          <div className="body">
            <div className="w-full overflow-x-auto">
              <Table
                dataSource={data}
                columns={columns}
                rowKey="id"
                loading={loading}
                pagination={{
                  current: currentPage,
                  pageSize: pageSize,
                  onChange: (page) => setCurrentPage(page),
                }}
              />
            </div>
          </div>
        </div>
      )}
      {section !== "default" && (
        <FormSection
          setSection={setSection}
          section={section}
          setTrigger={setTrigger}
          trigger={trigger}
          childData={childData}
        />
      )}
    </>
  );
}
