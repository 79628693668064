import React, { Suspense, useEffect } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import "./App.scss";
import AuthLayout from "./layouts/AuthLayout/AuthLayout";
import DashboardLayout from "./layouts/DashboardLayout/DashboardLayout";
import ListAdmin from "./pages/Admin/ListAdmin/ListAdmin";
import ForgotPassword from "./pages/Auth/ForgotPassword/ForgotPassword";
import Login from "./pages/Auth/Login/Login";
import ChangePassword from "./pages/Auth/SettingPassword/SettingPassword";
import ListBanner from "./pages/BannerArticle/ListBanner/ListBanner";
import CustomersList from "./pages/Customers/CustomersList";
import Dashboard from "./pages/Dashboard/Dashboard";
import ListInformation from "./pages/Information/ListInformation/ListInformation";
import BoardRoom from "./pages/OfficeManagement/BoardRoom/BoardRoom";
import CombBoardMeeting from "./pages/OfficeManagement/CombBoardMeeting/CombBoardMeeting";
import CoworkingSpaceD from "./pages/OfficeManagement/CoworkingSpaceD/CoworkingSpaceD";
import CoworkingSpaceND from "./pages/OfficeManagement/CoworkingSpaceND/CoworkingSpaceND";
import MeetingRoom from "./pages/OfficeManagement/MeetingRoom/MeetingRoom";
import OfficeList from "./pages/OfficeManagement/OfficeList/OfficeList";
import SeminarRoom from "./pages/OfficeManagement/SeminarRoom/SeminarRoom";
import ServiceOffice from "./pages/OfficeManagement/ServiceOffice/ServiceOffice";
import TheathreRoom from "./pages/OfficeManagement/TheathreRoom/TheathreRoom";
import store from "./redux/store";
import Discount from "./pages/PromoManagement/Discount/Discount";
// import Flashsale from "./pages/PromoManagement/Flashsale/Flashsale";
import PageLoading from "./components/organism/PageLoading";
// import RoomList from "./pages/RoomManagement/RoomList";
import Page404 from "./components/organism/Page404";
import AddonsList from "./pages/add-ons/AddonsList";
import BookingList from "./pages/BookingManagement/BookingList/BookingList";
import ClassRoom from "./pages/OfficeManagement/ClassRoom/ClassRoom";
import { getUserData } from "./redux/auth/action";
import NewsList from "./pages/News/News";
import CorporatePartnerList from "./pages/CoorporatePartner/CorporatePartner";
import ListArtikel from "./pages/CmsManagement/FAQ/FAQ";
import ContactUs from "./pages/CmsManagement/ContactUs/ContactUs";
import PromotionsList from "./pages/PromoManagement/promotions/Promotions";
import ListArticles from "./pages/Information/ListArticle/ListArticle";
import ServiceList from "./pages/Services/Service";
import { jwtDecode } from "jwt-decode";

const ProtectedRoute = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const token = useSelector((state) => state.auth.user.token);

  useEffect(() => {
    const checkAuthState = () => {
      const storedToken = localStorage.getItem("token");
      if (storedToken && !token) {
        dispatch(getUserData({ token: storedToken }));
      }
    };

    checkAuthState();
  }, [dispatch, token]);

  const isTokenExpired = (token) => {
    if (!token) return true; // If no token, consider it expired
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Current time in seconds
    return decodedToken.exp < currentTime; // Check if the token is expired
  };
  const tokens = localStorage.getItem("token");
  if (!isLoggedIn || !token || isTokenExpired(tokens)) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/admin/login" state={{ from: location }} replace />;
  }

  return children;
};

const App = () => (
  <Provider store={store}>
    <BrowserRouter>
      <Suspense fallback={<PageLoading />}>
        <Routes>
          <Route path="*" element={<Page404 />} />
          <Route
            path="/admin/login"
            element={
              <AuthLayout>
                <Login />
              </AuthLayout>
            }
          />
          <Route
            path="/admin/forgot-password"
            element={
              <AuthLayout>
                <ForgotPassword />
              </AuthLayout>
            }
          />
          {/* adding protectedRoute on change password since its protected */}
          {/* and only those who have the token can access and change their password */}
          <Route
            path="/admin/change-password"
            element={
              <AuthLayout>
                <ChangePassword />
              </AuthLayout>
            }
          />
          <Route path="*" element={<Page404 />} />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <Dashboard />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/list"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <ListAdmin />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/customers/list"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <CustomersList />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/addons/list"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <AddonsList />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/office/list"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <OfficeList />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/office/serviced-office"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <ServiceOffice />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/office/coworking-space-d"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <CoworkingSpaceD />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/office/coworking-space-nd"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <CoworkingSpaceND />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/office/meeting-room"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <MeetingRoom />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/office/seminar-room"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <SeminarRoom />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/office/board-room"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <BoardRoom />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/office/theathre-room"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <TheathreRoom />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/office/class-room"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <ClassRoom />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/office/comb-board-meeting"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <CombBoardMeeting />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          {/* <Route
            path="/room/list"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <RoomList />
                </DashboardLayout>
              </ProtectedRoute>
            }
          /> */}
          <Route
            path="/booking/list"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <BookingList />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/banner/list"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <ListBanner />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/information/list"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <ListInformation />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/news/list"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <ListArticles />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/our-service/list"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <ServiceList />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/cms/contact"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <ContactUs />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/cms/faq"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <ListArtikel />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/promo/discount"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <Discount />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/promotions/list"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <PromotionsList />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          {/* <Route
            path="/best-deals/list"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <Discount />
                </DashboardLayout>
              </ProtectedRoute>
            }
          /> */}
          <Route
            path="/news/list"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <NewsList />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/partnership/list"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <CorporatePartnerList />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          {/* <Route
            path="/promo/flashsale"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <Flashsale />
                </DashboardLayout>
              </ProtectedRoute>
            }
          /> */}
        </Routes>
      </Suspense>
    </BrowserRouter>
  </Provider>
);

export default App;
