import React, { useState } from "react";
import {
  Form,
  // Input,
  notification,
  // Upload,
  // Select,
  // message,
  // Timeline,
} from "antd";
import {
  ButtonBack,
  Tags,
  // UploadButton,
} from "../../../../components/atoms";
// import { FileEarmarkTextFill } from "react-bootstrap-icons";
import moment from "moment";
import {
  // getListCourier,
  // processOrders,
  // rejectOrders,
  // requestDeliveryConfirmation,
  // uploadGeneral,
  // getTrackingOrders,
  getOrderCreditLimit,
  cancelBooking,
} from "../../../../services/api";
import {
  formatRupiahBookingDetail,
  translateBookingStatus,
  translatePaymentStatus,
} from "../../../../utils/Helper";

export default function OrderDetails(props) {
  const { childData, formatColorStatus, setSection, formatColorPaymentStatus } =
    props;
  const [productData, setProductData] = useState([]);
  const [creditLimit, setCreditLimit] = useState([]);
  const [form] = Form.useForm();
  const [params, setParams] = useState({});
  const paramsCreditLimit = {};

  const getCreditLimits = () => {
    getOrderCreditLimit(paramsCreditLimit)
      .then((res) => {
        setCreditLimit(res.data.data);
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [loading, setLoading] = useState(false);

  const handleCancelSubmit = async (id, explanation) => {
    setLoading(true);
    try {
      const data = {
        explanation: explanation,
      };

      const response = await cancelBooking(id, data);

      if (response.status === 200) {
        notification.success({
          message: "Success",
          description: "You have successfully canceled this booking",
        });
      } else {
        notification.error({
          message: "Error",
          description: response.data.message,
        });
      }

      setIsModalOpen(false);
    } catch (error) {
      notification.error({
        message: "Error",
        description: error.message,
      });
      // Handle error (e.g., show an error message)
    } finally {
      setLoading(false);
    }
  };

  const styles = {
    modalOverlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 1000,
    },
    modal: {
      background: "white",
      padding: "20px",
      borderRadius: "8px",
      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
      position: "relative",
      width: "90%",
      maxWidth: "500px",
    },
    modalClose: {
      position: "absolute",
      top: "10px",
      right: "10px",
      background: "none",
      border: "none",
      fontSize: "1.5rem",
      cursor: "pointer",
    },
    modalContent: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    textarea: {
      width: "100%",
      padding: "10px",
      border: "1px solid #ccc",
      borderRadius: "4px",
    },
    buttonPrimary: {
      backgroundColor: "#007bff",
      color: "white",
      border: "none",
      padding: "10px 20px",
      cursor: "pointer",
      borderRadius: "4px",
    },
    buttonPrimaryDisabled: {
      backgroundColor: "#ccc",
      cursor: "not-allowed",
    },
    buttonDanger: {
      backgroundColor: "#dc3545",
      color: "white",
      border: "none",
      padding: "10px 20px",
      cursor: "pointer",
      borderRadius: "4px",
    },
    buttonDangerDisabled: {
      backgroundColor: "#ccc",
      cursor: "not-allowed",
    },
  };

  const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) {
      return null;
    }

    return (
      <div style={styles.modalOverlay}>
        <div style={styles.modal}>
          <button style={styles.modalClose} onClick={onClose}>
            &times;
          </button>
          <div style={styles.modalContent}>{children}</div>
        </div>
      </div>
    );
  };

  let grandTotal = 0;

  function calculateTotalPrice(order) {
    let totalPrice = 0;
    let lastValidQuantity = 0;

    order?.product_wholesale_price?.forEach((item) => {
      if (order.qty_po >= item.quantity) {
        lastValidQuantity = item.quantity;
      }
    });

    if (lastValidQuantity > 0) {
      let lastValidPrice = order.product_wholesale_price.find(
        (item) => item.quantity === lastValidQuantity
      ).price;
      totalPrice = lastValidPrice * order.qty_po;
    } else {
      totalPrice = order.product_price * order.qty_po;
    }
    if (lastValidQuantity !== 0) {
      console.log(
        `Quantity item telah mencakup minimal ${lastValidQuantity} maka akan dikenakan harga grosir`
      );
    } else {
      console.log(
        "Quantity tidak mencakup minimal harga grosir / tidak ada harga grosir di produk tersebut"
      );
    }
    return totalPrice;
  }

  productData.forEach((order) => {
    let totalPrice = calculateTotalPrice(order);
    grandTotal += totalPrice;
    console.log(
      `Total harga untuk pesanan ${order.product_name}: ${totalPrice}`
    );
    console.log(`Total harga untuk order ${grandTotal}`);
  });

  return (
    <>
      <div className="content-section">
        <div className="header">
          <div className="flex items-center">
            <ButtonBack onClick={() => setSection("default")} />
            <div className="header-title">Order Details</div>
          </div>
        </div>
        <div className="body-with-footer">
          <div className="body-content">
            <div className="flex items-center justify-between p-6 bg-primary text-white rounded-t-lg  shadow-md">
              <h2 className="font-bold">Booking Detail</h2>
              <Tags
                color={formatColorStatus(childData.booking_status)}
                title={translateBookingStatus(childData.booking_status)}
              />
            </div>
            <div className="booking-details p-6 border border-gray-300 rounded-b-lg shadow-md bg-white">
              <div className="grid grid-cols-2 gap-6">
                <div>
                  <div className="text-secondary">Booking Number</div>
                  <div className="">{childData.booking_invoice || "-"}</div>
                </div>
                <div>
                  <div className="text-secondary">Booking Status</div>
                  <div>
                    {translateBookingStatus(childData.booking_status) || "-"}
                  </div>
                </div>
                <div>
                  <div className="text-secondary">Customer Name</div>
                  <div>{childData.customer_name || "-"}</div>
                </div>
                <div>
                  <div className="text-secondary">Office Name</div>
                  <div>{childData.office_name || "-"}</div>
                </div>
                <div className="col-span-2">
                  <div className="text-secondary mb-2">Booking Date</div>
                  <div className="">
                    <div className="">
                      {moment(childData.booking_start_date)
                        .zone("+07:00")
                        .format("DD/MM/YYYY hh:mm A")}
                    </div>
                    <span className="text-secondary">to</span>
                    <div className="">
                      {moment(childData.booking_end_date)
                        .zone("+07:00")
                        .format("DD/MM/YYYY hh:mm A")}
                    </div>
                  </div>
                </div>

                <div>
                  <div className="text-secondary">Hour Count</div>
                  <div>{childData.hour_count || "-"}</div>
                </div>
                <div>
                  <div className="text-secondary">Days Count</div>
                  <div>{childData.days_count || "-"}</div>
                </div>
                <div>
                  <div className="text-secondary">Week Count</div>
                  <div>{childData.week_count || "-"}</div>
                </div>
                <div>
                  <div className="text-secondary">Month Count</div>
                  <div>{childData.month_count || "-"}</div>
                </div>

                <div className="col-span-2 border-t border-gray-300 pt-4">
                  <div className="text-secondary">Payment Type</div>
                  <div>
                    {childData.payment_type === "payment_gateway"
                      ? "Payment Gateway"
                      : "Unknown Payment Type"}
                  </div>
                </div>
                <div>
                  <div className="text-secondary">Payment URL</div>
                  <div>
                    {childData.va_account ? (
                      <a
                        href={childData.va_account}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 underline"
                      >
                        Click Here
                      </a>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>

                <div className="col-span-2 border-t border-gray-300 pt-4 grid grid-cols-2 gap-6">
                  <div>
                    <div className="text-secondary">Booking Price</div>
                    <div>
                      {formatRupiahBookingDetail(childData.booking_price)}
                    </div>
                  </div>
                  <div>
                    <div className="text-secondary">Overtime Price</div>
                    <div>
                      {formatRupiahBookingDetail(childData.overtime_price)}
                    </div>
                  </div>
                  <div>
                    <div className="text-secondary">Add-ons Price</div>
                    <div>
                      {formatRupiahBookingDetail(childData.catering_price)}
                    </div>
                  </div>
                  <div>
                    <div className="text-secondary">Service Fee</div>
                    <div className="font-bold">
                      {formatRupiahBookingDetail(childData.service_fee)}
                    </div>
                  </div>
                  <div>
                    <div className="text-secondary">Subtotal</div>
                    <div className="font-bold">
                      {formatRupiahBookingDetail(childData.total_price)}
                    </div>
                  </div>

                  {/* <div className="col-span-2"> */}
                  <div>
                    <div className="text-secondary">Deposit</div>
                    <div>{formatRupiahBookingDetail(childData.deposit)}</div>
                  </div>
                  <div>
                    <div className="text-secondary">VAT 11%</div>
                    <div>{formatRupiahBookingDetail(childData.ppn)}</div>
                  </div>

                  <div className="col-span-2">
                    <div className="text-secondary text-lg">Total</div>
                    <div className="font-bold text-lg">
                      {formatRupiahBookingDetail(childData.final_price)}
                    </div>
                  </div>
                </div>

                <div className="col-span-2 border-t border-gray-300 pt-4">
                  <div className="text-secondary">User Description</div>
                  <div>{childData.uses_description || "-"}</div>
                </div>
                <div className="col-span-2">
                  <div className="text-secondary">Notes</div>
                  <div>{childData.notes || "-"}</div>
                </div>
              </div>
            </div>

            {/* Catering Details Section */}

            {childData.catering_details &&
              childData.catering_details.length > 0 && (
                <div className="catering-details mt-6">
                  <h2 className="text-primary font-bold mb-4">
                    Add-ons Detail
                  </h2>
                  <div className="border border-gray-300 rounded-lg overflow-hidden">
                    <div className="grid grid-cols-4 bg-[#7F0D0D] p-2 font-bold text-center">
                      <div className="text-white">Item</div>
                      <div className="text-white">Unit Price</div>
                      <div className="text-white">Quantity</div>
                      <div className="text-white">Amount</div>
                    </div>
                    {childData.catering_details.map((catering, index) => (
                      <div
                        key={index}
                        className="grid grid-cols-4 p-2 text-center border-b border-gray-300"
                      >
                        <div>{catering.catering_name || "-"}</div>
                        <div>
                          {formatRupiahBookingDetail(
                            catering.catering_unit_price
                          )}
                        </div>
                        <div>{catering.catering_count_pax}</div>
                        <div>
                          {formatRupiahBookingDetail(catering.catering_price)}
                        </div>
                      </div>
                    ))}
                    <div className="grid grid-cols-4 p-2 font-bold text-center bg-gray-100">
                      <div className="col-span-3 text-right pr-4">Total</div>
                      <div>
                        {formatRupiahBookingDetail(childData.catering_price)}
                      </div>
                    </div>
                  </div>
                </div>
              )}

            {/* Payment Details Section */}
            {childData.payment && (
              <div className="payment-details mt-6">
                <h2 className="text-primary font-bold">Payment Details</h2>
                <div className="grid grid-cols-2 gap-4 mt-2">
                  <div>
                    <div className="text-secondary">Payment Invoice</div>
                    <div>{childData.payment.payment_invoice || "-"}</div>
                  </div>
                  <div>
                    <div className="text-secondary">Payment Status</div>
                    <div>
                      {/* {translateBookingStatus(
                        childData.payment.payment_status
                      ) || "-"} */}
                      <Tags
                        color={formatColorPaymentStatus(
                          childData.payment.payment_status
                        )}
                        title={translatePaymentStatus(
                          childData.payment.payment_status
                        )}
                      ></Tags>
                    </div>
                  </div>
                  <div>
                    <div className="text-secondary">Total Price</div>
                    <div>
                      {formatRupiahBookingDetail(childData.payment.total_price)}
                    </div>
                  </div>
                  <div>
                    <div className="text-secondary">Expired At</div>
                    <div>
                      {/* {moment(childData.payment.expired_at).format(
                        "DD/MM/YYYY hh:ss"
                      )} */}
                      {moment(childData.payment.expired_at)
                        .zone("+07:00")
                        .format("DD/MM/YYYY hh:mm A")}
                    </div>
                  </div>
                  <div>
                    <div className="text-secondary">Created At</div>
                    <div>
                      {moment(childData.payment.created_at)
                        .zone("+07:00")
                        .format("DD/MM/YYYY hh:mm A")}
                      {/* {moment(childData.payment.created_at).format(
                        "DD/MM/YYYY hh:ss"
                      )} */}
                    </div>
                  </div>
                  <div>
                    <div className="text-secondary">Updated At</div>
                    <div>
                      {moment(childData.payment.updated_at)
                        .zone("+07:00")
                        .format("DD/MM/YYYY hh:mm A")}
                      {/* {moment(childData.payment.updated_at).format(
                        "DD/MM/YYYY hh:ss"
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {isModalOpen && (
        <Modal onClose={() => setIsModalOpen(false)}>
          <div className="modal-content">
            <h2>Cancel Booking</h2>
            <textarea
              placeholder="Reason for cancellation"
              value={cancelReason}
              onChange={(e) => setCancelReason(e.target.value)}
            ></textarea>
            <button
              className="btn btn-primary"
              onClick={handleCancelSubmit}
              disabled={loading}
            >
              Submit
            </button>
            {loading && <div>Loading...</div>}
          </div>
        </Modal>
      )}
    </>
  );
}
