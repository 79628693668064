import React, { useEffect, useState } from "react";
import {
  Input,
  Col,
  Row,
  Form,
  notification,
  Switch,
  Image,
  Button,
  Upload,
} from "antd";
import { Buttons, ButtonBack } from "../../../components/atoms/index";
import validateMessage from "../../../utils/validateMessage";
import {
  postInformation,
  putInformation,
  uploadGeneral,
} from "../../../services/api";
import { UploadOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const { TextArea } = Input;

export default function FormSection(props) {
  const { setSection, section, setTrigger, trigger, childData } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    const { name, description } = values;

    const result = {
      name,
      description,
    };

    if (section === "add") {
      setLoading(true);
      await postInformation(result)
        .then((res) => {
          if (res) {
            notification.success({
              message: "Success!",
              description: "Successfully Added Information",
              placement: "topRight",
            });
            setSection("default");
            setTrigger(!trigger);
          }
        })
        .catch((err) => {
          notification.error({
            message: "Failed!",
            description: "Failed to Add Information",
            placement: "topRight",
          });
          throw new Error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (section === "edit") {
      setLoading(true);
      await putInformation(childData.id, result)
        .then((res) => {
          if (res) {
            notification.success({
              message: "Success!",
              description: "Successfully Edit Informations",
              placement: "topRight",
            });
            setSection("default");
            setTrigger(!trigger);
          }
        })
        .catch((err) => {
          notification.error({
            message: "Failed!",
            description: "Failed to Edit Information",
            placement: "topRight",
          });
          throw new Error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (section === "edit") {
      form.setFieldsValue({
        name: childData.name,
        description: childData.description,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const normFile = (e) => {
    // console.log("Upload event:", e);

    if (Array.isArray(e)) {
      return e;
    }

    return e?.fileList;
  };

  return (
    <>
      <div className="content-section">
        <div className="header">
          <div className="flex items-center">
            <ButtonBack onClick={() => setSection("default")} />
            <div className="header-title">
              {section === "add" && "Add Information"}
              {section === "view" && "Detail"}
              {section === "edit" && "Edit Information"}
            </div>
          </div>
        </div>
        {section !== "view" ? (
          <div className="body-with-footer">
            <Form
              form={form}
              onFinish={onFinish}
              layout="vertical"
              validateMessages={validateMessage}
            >
              <div className="body-content">
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="Name"
                      name="name"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input placeholder="Type the Information title" />
                    </Form.Item>
                    <Form.Item
                      label="Description"
                      name="description"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <ReactQuill placeholder="Type a description" />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <div className="footer">
                <Buttons onClick={() => setSection("default")}>Cancel</Buttons>
                <Form.Item type="button">
                  <Buttons type="primary" htmlType="submit" loading={loading}>
                    Submit
                  </Buttons>
                </Form.Item>
              </div>
            </Form>
          </div>
        ) : (
          <div className="body">
            <Row>
              <Col span={12}>
                <div className="flex items-center gap-4 text-lg mb-3">
                  <Image src={childData.link} height={50} width={130} />
                  <span>{childData.title}</span>
                </div>
                <div className="text-gray-500 mb-2">Description</div>
                <div>{childData.description}</div>
              </Col>
              <Col span={12}>
                <div className="text-gray-500 mb-2">Order</div>
                <div>{childData.id}</div>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </>
  );
}
