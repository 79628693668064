import React, { useState, useEffect } from "react";
import { Images, Tags } from "../../../components/atoms/index";
import { formatRupiah } from "../../../utils/Helper";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

export default function PartnerDetail(props) {
  // icon marker
  L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  });
  const {
    childData,
    CorporatePartnerInformationRef,
    CorporatePartnerImageRef,
  } = props;
  const [listImage, setListImage] = useState([]);

  useEffect(() => {
    getListImage();
  }, []);

  function getListImage() {
    const images = [childData.url_image];
    setListImage(images);
  }

  return (
    <div className="pb-8 pt-2">
      <div>
        <h2
          ref={CorporatePartnerInformationRef}
          className="text-lg text-primary mb-2 font-bold"
        >
          Corporate Partner Information
        </h2>
        <div className="grid grid-cols-2 gap-8 mb-4">
          <div>
            <div className="text-secondary mb-2">
              Corporate Partner Pictures
            </div>
            <div className="flex gap-4 mb-4">
              {listImage?.map((item, index) => (
                <Images src={item} key={index} width={160} height={160} />
              ))}
              {listImage?.length < 1 && <div>No pictures</div>}
            </div>
            <div>
              <div className="text-secondary">Corporate Partner Name</div>
              <div>
                <strong>{childData.name}</strong>
              </div>
            </div>
          </div>
          <div>
            <h2 className="text-lg font-semibold text-primary mb-2">
              Description
            </h2>
            {/* <div>{childData.description || "-"}</div> */}
            <div dangerouslySetInnerHTML={{ __html: childData.description }} />
          </div>
        </div>
      </div>
    </div>
  );
}
