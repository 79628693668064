import React, { useEffect, useState } from "react";
import {
  Input,
  Col,
  Row,
  Form,
  notification,
  Upload,
  Button,
  message,
  Divider,
  Image,
  Modal,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Buttons, ButtonBack } from "../../../components/atoms";
import validateMessage, { getLabel } from "../../../utils/validateMessage";
import { postArticle, putArticle } from "../../../services/api";
import TextEditor from "../../../components/molecules/TextEditor";
import ReactQuill from "react-quill";
import ArticlesDetail from "./ArticleDetail";
import { Link } from "react-router-dom";

export default function FormSection(props) {
  const { setSection, section, setTrigger, trigger, childData } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState("");
  const [fileList, setFileList] = useState([]);

  // handle file upload
  const [file, setFile] = useState();
  const [fileBase64, setFileBase64] = useState();
  const [modalPreview, setModalPreview] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async (values) => {
    if (!file && section === "add") {
      notification.error({
        message: "Error",
        description: "You must provide a photo for News!",
      });
      return;
    }
    const { name, author, description } = values;
    const formData = new FormData();

    const cleanDescription = description.replace(/<[^>]+>/g, "");

    formData.append("name", name);
    formData.append("description", description);
    formData.append("author", author);
    formData.append("image", file.originFileObj || file);

    // if (section === "edit") {
    //   formData.append("image", childData.image);
    // } else if (fileList.length > 0) {
    //   formData.append("image", fileList[0].originFileObj);
    // } else {
    //   notification.error({
    //     message: "Upload Failed!",
    //     description: "Please upload an image.",
    //     placement: "topRight",
    //   });
    //   return;
    // }

    setLoading(true);
    try {
      if (section === "add") {
        const res = await postArticle(formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (res) {
          notification.success({
            message: "Success!",
            description: "Successfully added news.",
            placement: "topRight",
          });
          setSection("default");
          setTrigger(!trigger);
        }
      } else if (section === "edit") {
        const res = await putArticle(childData.id, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (res) {
          notification.success({
            message: "Success!",
            description: "Successfully edited news.",
            placement: "topRight",
          });
          setSection("default");
          setTrigger(!trigger);
        }
      }
    } catch (err) {
      notification.error({
        message: "Failed!",
        description:
          section === "add" ? "Failed to add news." : "Failed to edit news.",
        placement: "topRight",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (section !== "add") {
      form.setFieldsValue({
        name: childData.name,
        author: childData.author,
        description: childData.description,
      });
      setFileList([]);
    }
  }, [section, childData, form]);

  useEffect(() => {
    if (section === "edit") {
      setFile(childData.url_image ? { image: childData.url_image } : null);
      if (childData.url_image) {
        setFileBase64(childData.url_image);
      }

      form.setFieldsValue({
        name: childData.name,
        description: childData.description,
        author: childData.author,
        image: childData.url_image,
      });
    }
  }, [section, childData, form]);

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return false;
    }

    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must be smaller than 2MB!");
      return false;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      setFileBase64(e.target.result);
    };
    reader.readAsDataURL(file);
    setFile(file);
    return false;
  };

  const handlePreview = () => {
    setModalPreview(true);
  };

  const normFile = (e) => {
    return e?.fileList;
  };

  const handleRemove = () => {
    setFile(null);
    setFileBase64(null);
  };
  const handleUploadChange = (info) => {
    setFileList(info.fileList);
  };

  return (
    <>
      <div className="content-section">
        <div className="header">
          <div className="flex items-center">
            <ButtonBack onClick={() => setSection("default")} />
            <div className="header-title">
              {section === "add" && "Add News"}
              {section === "view" && "Detail"}
              {section === "edit" && "Edit News"}
            </div>
          </div>
          <div className="breadcrumb mb-4 text-md">
            <Link to="/" className="text-gray-500">
              Dashboard
            </Link>
            <span className="mx-2">&gt;</span>
            <Link
              to=""
              onClick={() => setSection("default")}
              className="text-gray-500"
            >
              News List
            </Link>
            {section !== "default" && (
              <>
                <span className="mx-2">&gt;</span>
                <span>
                  {section === "add"
                    ? "Add News"
                    : section === "view"
                    ? "Detail"
                    : "Edit News"}
                </span>
              </>
            )}
          </div>
        </div>

        <div className="body max-body-height pt-3 pb-0">
          {section !== "view" ? (
            <Form
              form={form}
              onFinish={onFinish}
              layout="vertical"
              validateMessages={validateMessage}
            >
              <div className="body-content">
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="Title of News"
                      name="name"
                      rules={[{ required: true }]}
                    >
                      <Input
                        placeholder="Type the News title"
                        disabled={section === "view"}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Author's Name"
                      name="author"
                      rules={[{ required: true }]}
                    >
                      <Input
                        placeholder="Type the author's name"
                        disabled={section === "view"}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    {/* <Form.Item
                      name="upload"
                      label="News Image"
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                      extra="Recommended dimensions 800 x 600. 1MB maximum with format PNG, JPEG"
                      rules={
                        section === "add"
                          ? [{ required: true }]
                          : [{ required: false }]
                      }
                    >
                      <Upload
                        name="file"
                        beforeUpload={() => false}
                        maxCount={1}
                        onChange={handleUploadChange}
                        disabled={section === "view"}
                      >
                        <Button
                          className="flex items-center"
                          icon={<UploadOutlined />}
                        >
                          Upload File
                        </Button>
                      </Upload>
                    </Form.Item> */}
                  </Col>
                </Row>
                <Row>
                  <Col span={8} className="mr-5">
                    <div className="text-xl font-bold text-black mb-2">
                      Upload Photo
                    </div>
                    <div className="text-md font-regular text-gray-500 mb-6">
                      Only 1 (one) photo can be uploaded.
                    </div>
                    <Form.Item
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                      name={"attachment"}
                      extra="Recommended dimensions 800 x 600. 1MB maximum with format PNG, JPEG"
                    >
                      <Upload.Dragger
                        listType="picture-card"
                        onPreview={handlePreview}
                        onRemove={handleRemove}
                        beforeUpload={beforeUpload}
                        maxCount={1}
                        fileList={file ? [file] : []}
                        showUploadList={false}
                        accept="image/jpeg,image/png"
                      >
                        <p className="ant-upload-drag-icon">
                          <UploadOutlined className="text-primary" />
                        </p>
                        <p className="ant-upload-text font-bold text-primary text-md">
                          Drag and Drop
                          <span className="font-medium text text-gray-600">
                            {" "}
                            photos here
                          </span>
                        </p>
                        <p className="ant-upload-hint">
                          PNG, JPG, Max size. 2MB
                        </p>
                        <p className="mt-3 mb-3 text-gray-400">or</p>
                        <Button type="primary">Upload Photo</Button>
                      </Upload.Dragger>
                    </Form.Item>
                  </Col>
                  {file && (
                    <>
                      <Divider
                        type="vertical"
                        className="h-80"
                        style={{ borderLeft: "1px solid #ccc" }}
                      />
                      <Col span={8} className="ml-5">
                        <div className="text-xl font-bold text-black mb-2">
                          Uploaded Photo (1)
                        </div>
                        {fileBase64 ? (
                          <Image
                            className="w-60 rounded-lg object-cover cursor-pointer"
                            src={fileBase64}
                            alt="uploaded"
                          />
                        ) : (
                          <div className="text-red-500">
                            Failed to load image preview
                          </div>
                        )}
                        <Modal
                          open={modalPreview}
                          footer={null}
                          onCancel={() => setModalPreview(false)}
                        >
                          {fileBase64 && (
                            <img
                              style={{ width: "100%" }}
                              src={fileBase64}
                              alt="Preview"
                            />
                          )}
                        </Modal>
                      </Col>
                    </>
                  )}
                </Row>
                <Form.Item
                  label={getLabel("Description", false)}
                  name="description"
                >
                  <ReactQuill
                    placeholder="Type a description"
                    // value={content}
                    // onChange={setContent}
                  />
                </Form.Item>
              </div>
              <div className="border-t-2 -mx-5 mt-12">
                <div className="flex justify-end gap-3 px-5 pt-4">
                  <Buttons
                    type={"default"}
                    onClick={() => setSection("default")}
                  >
                    Cancel
                  </Buttons>
                  <Form.Item type="button">
                    <Buttons type="primary" htmlType="submit">
                      Save
                    </Buttons>
                  </Form.Item>
                </div>
              </div>
            </Form>
          ) : (
            <ArticlesDetail {...props} />
          )}
        </div>
      </div>
    </>
  );
}
