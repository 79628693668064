import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import loginHero from "../../../assets/images/loginHero.svg";
import validateMessage from "../../../utils/validateMessage";
import { authLogin } from "../../../services/api";
import { Form, Input, Alert, notification, Checkbox } from "antd";
import { useDispatch } from "react-redux";
import { getUserData } from "../../../redux/auth/action";
import ButtonsPrimary from "../../../components/atoms/ButtonsPrimary";

export default function Login() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState(false);

  const emailRef = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (emailRef.current) {
      emailRef.current.focus();
    }
  }, []);

  const onFinish = async (values) => {
    try {
      const res = await authLogin(values);
      if (res.status === 200) {
        dispatch(getUserData(res.data.data));
        navigate("/");
        notification.success({
          message: "Login Success!",
          description: "You have successfully logged in to the dashboard",
        });
      }
    } catch (err) {
      setError(true);
      notification.error({
        message: "Login Failed!",
        description:
          err.response?.data?.message || "Incorrect Email or Password",
        placement: "topRight",
      });
    }
  };

  const handleRememberMe = (e) => {
    setRememberMe(e.target.checked);
  };

  return (
    <div className="flex flex-row-reverse items-center justify-center h-full gap-x-24 px-5 bg-white">
      <img
        src={loginHero}
        alt="Hero"
        style={{ width: 450, height: 480 }}
        className="md:block hidden"
      />
      <div className="card shadow-lg bg-white rounded-lg p-16 md:w-[600px] w-full">
        <h3 className="text-2xl text-gray-900 font-semibold pt-2 pb-1 mb-4">
          Login
        </h3>
        <p className="text-md font-light text-gray-500 mb-8">
          Welcome back! Please log in to access admin dashboard.
        </p>

        {error && (
          <Alert
            message="Incorrect Email or Password!"
            type="error"
            showIcon
            className="mb-2"
          />
        )}

        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          validateMessages={validateMessage}
          onValuesChange={() => setError(false)}
        >
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter a valid email address",
              },
            ]}
          >
            <Input placeholder="email@example.com" ref={emailRef} />
          </Form.Item>

          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: "Please enter your password",
              },
            ]}
          >
            <Input.Password placeholder="Enter password" />
          </Form.Item>
          <div className="flex md:flex-row flex-col justify-between mb-4">
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox
                checked={rememberMe}
                onChange={handleRememberMe}
                className="custom-checkbox"
              >
                Remember me
              </Checkbox>
            </Form.Item>
            <a
              className="cursor-pointer text-primary hover:underline"
              onClick={(e) => {
                e.preventDefault();
                navigate("/admin/forgot-password");
              }}
              href="/admin/forgot-password"
            >
              Forgot Password?
            </a>
          </div>

          <Form.Item shouldUpdate>
            {() => (
              <ButtonsPrimary
                type="primary"
                htmlType="submit"
                customclass="w-full"
                disabled={
                  !form.isFieldsTouched(["email", "password"]) ||
                  form
                    .getFieldsError()
                    .filter(
                      ({ name }) => name === "email" || name === "password"
                    )
                    .some(({ errors }) => errors.length)
                }
              >
                Login
              </ButtonsPrimary>
            )}
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
