import {
  DeleteFilled,
  EditFilled,
  ExclamationCircleOutlined,
  EyeFilled,
  PlusOutlined,
} from "@ant-design/icons";
import { Modal, notification, Table } from "antd";
import { saveAs } from "file-saver";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BiDownload } from "react-icons/bi";
import { Buttons, InputSearch, Tags } from "../../../components/atoms/index";
import { DeleteData, getAllAdmin } from "../../../services/api";
import formatRole from "../../../utils/formatRole";
import FormSection from "./FormSection";

const { confirm } = Modal;

export default function ListAdmin() {
  const [data, setData] = useState([]);
  const [totalData, setTotal] = useState(0);
  const [section, setSection] = useState("default");
  const [trigger, setTrigger] = useState(false);
  const [childData, setChildData] = useState({});
  const [reload, setReload] = useState(0);
  // const [totalData, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({
    offset: 1,
    limit: 10,
    order: "created_at,desc",
    search: "",
  });
  const [loadingDownload, setLoadingDownload] = useState(false);

  const username = "ceosuiteadmin";
  const password = "c304dm1n0nly";
  const basicAuth = `Basic ${btoa(`${username}:${password}`)}`;

  useEffect(() => {
    getData();
  }, [trigger, params, reload]);

  const getData = async () => {
    setLoading(true);
    try {
      const response = await getAllAdmin(params);
      // Safely access nested data with optional chaining
      const adminData = response?.data?.data?.result;

      if (Array.isArray(adminData)) {
        setData(adminData);
      } else if (adminData) {
        setData([adminData]);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error("Error fetching admins:", error);
      setData([]);
      // toast({
      //   variant: "destructive",
      //   title: "Error",
      //   description: "Failed to fetch admin list"
      // });
    } finally {
      setLoading(false);
    }
  };

  function onPageChange(page, pageSize) {
    const offset = (page - 1) * pageSize;
    const limit = pageSize;
    setParams({ ...params, offset, limit });
  }

  const onSearch = _.debounce(function (e) {
    setParams({ ...params, search: e.target.value });
  }, 500);

  useEffect(() => {
    if (section === "default") {
      setChildData({});
    }
  }, [section]);

  const columns = [
    {
      title: "Full Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      sorter: (a, b) => a.phone_number - b.phone_number,
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (role) => {
        return <Tags color={formatRole(role)} title={`${role}`} />;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <>
          <div className="flex items-center gap-x-1">
            <Buttons
              className="py-1 rounded-lg hover:border-primary"
              icon={<EyeFilled className="text-gray-600 hover:text-primary" />}
              onClick={() => viewData(record)}
            />
            <Buttons
              className="py-1 rounded-lg hover:border-primary"
              icon={<EditFilled className="text-gray-600 hover:text-primary" />}
              onClick={() => editData(record)}
            />
            <Buttons
              className="py-1 rounded-lg hover:border-primary"
              icon={
                <DeleteFilled className="text-gray-600 hover:text-primary" />
              }
              onClick={() => confirmDeleteData(record.id)}
            />
          </div>
        </>
      ),
    },
  ];

  const editData = (record) => {
    setChildData(record);
    setSection("edit");
  };

  const viewData = (record) => {
    // console.log(record);
    setChildData(record);
    setSection("view");
  };

  const addData = () => {
    setSection("add");
  };

  const confirmDeleteData = (id) => {
    confirm({
      title: "Confirm Delete",
      content:
        "Are you sure you want to delete this data? This action cannot be undone.",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        return new Promise((resolve, reject) => {
          DeleteData(id)
            .then((res) => {
              notification.success({
                message: "Success!",
                description: "Successfully delete data",
                placement: "topRight",
              });
              setReload(reload + 1);
              // getData();
              resolve(res);
            })
            .catch((err) => {
              notification.error({
                message: "Failed!",
                description: "Failed to delete data",
                placement: "topRight",
              });
              reject(err);
            });
        });
      },
    });
  };

  // const [loadingDownload, setLoadingDownload] = useState(false);

  // const handleDownloadData = () => {
  //   setLoadingDownload(true);
  //   downloadListAdminUsers(params)
  //     .then((res) => {
  //       downloadFile(res.data.data);
  //     })
  //     .catch((err) => {
  //       return new Error(err);
  //     })
  //     .finally(() => {
  //       setLoadingDownload(false);
  //     });
  // };

  const downloadFile = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/users/report?user_type=admin`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Auth-Special-CeoSuite": basicAuth,
          },
        }
      );

      const blob = await response.blob();
      saveAs(blob, `REPORT_${moment().format("l")}.xlsx`);
    } catch (error) {
      console.error("Failed to download file:", error);
    }
  };

  return (
    <>
      {section === "default" && (
        <div className="content-section">
          <div className="header">
            <h2 className=" lg:text-[28px] text-[20px]">List Admin</h2>
            <Buttons
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => addData()}
              disabled={true}
            >
              Add Data
            </Buttons>
          </div>
          <div className="body">
            <div className="flex justify-between">
              <div className="mb-2 md:w-80 w-32">
                <InputSearch onChange={onSearch} placeholder="Search Name" />
              </div>
              <Buttons
                type="success"
                icon={<BiDownload />}
                loading={loadingDownload}
                onClick={downloadFile}
              >
                Download Data
              </Buttons>
            </div>
            <div className="w-full overflow-x-auto">
              <Table
                dataSource={data}
                // dataSource={dummyData}
                columns={columns}
                rowKey="id"
                // loading={loading}
              />
            </div>
          </div>
        </div>
      )}
      {section !== "default" && (
        <FormSection
          setSection={setSection}
          section={section}
          setTrigger={setTrigger}
          trigger={trigger}
          childData={childData}
        />
      )}
    </>
  );
}
