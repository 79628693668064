import React, { useState, useEffect } from "react";
import { Images, Tags } from "../../../components/atoms/index";
import {
  formatPromoType,
  formatPromoTypeColor,
  formatRupiah,
} from "../../../utils/Helper";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import moment from "moment";

export default function ServiceDetail(props) {
  // icon marker
  L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  });

  const { childData, AddOnsInformationRef, AddOnsImageRef, AddOnsPriceRef } =
    props;
  const [listImage, setListImage] = useState([]);

  useEffect(() => {
    getListImage();
  }, []);

  function getListImage() {
    const images = [childData.url_image];
    setListImage(images);
  }

  return (
    <div className="pb-8 pt-2">
      <div>
        <h2
          ref={AddOnsInformationRef}
          className="text-lg font-semibold text-primary mb-2"
        >
          Service Detail Information
        </h2>
        <div className="mb-4">
          <div className="text-secondary mb-2">Service Picture</div>
          <div className="flex gap-4 mb-4">
            {listImage?.map((item, index) => (
              <Images src={item} key={index} height={350} />
            ))}
            {listImage?.length < 1 && <div>No picture</div>}
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <div className="text-secondary">Service Name</div>
            <div className="font-semibold">{childData.name}</div>
          </div>
          <div>
            <div className="text-secondary">Created At</div>
            <div>{moment(childData.start_date).format("L")}</div>
          </div>
        </div>
        <h2
          ref={AddOnsImageRef}
          className="mt-6 text-lg font-semibold text-primary mb-2"
        >
          Preview
        </h2>
        <div className="grid grid-cols-1 gap-4">
          <div>
            <div
              dangerouslySetInnerHTML={{
                __html: childData.sub_name || "No Preview...",
              }}
            />
          </div>
        </div>
        <h2
          ref={AddOnsImageRef}
          className="mt-6 text-lg font-semibold text-primary mb-2"
        >
          Description
        </h2>
        <div className="grid grid-cols-1 gap-4">
          <div>
            <div
              dangerouslySetInnerHTML={{
                __html: childData.description || "No Description...",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
