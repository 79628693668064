import React, { useEffect, useRef, useState } from "react";
import { Form, Input, notification } from "antd";
import { Buttons } from "../../../components/atoms/index";
import logo from "../../../assets/icons/Logo.svg";
import validateMessage from "../../../utils/validateMessage";
import { CheckCircleFilled } from "@ant-design/icons";
import { ChevronLeft } from "react-bootstrap-icons";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { changePassword } from "../../../services/api";

export default function SettingPassword() {
  const [validateLength, setValidateLength] = useState(false);
  const [validateUpperLower, setValidateUpperLower] = useState(false);
  const [validateNumber, setValidateNumber] = useState(false);
  const [validateSymbol, setValidateSymbol] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const location = useLocation();
  const passwordRef = useRef(null);

  useEffect(() => {
    passwordRef.current.focus();
    // the bug that we have been having is because whenever we accessed the
    // change password page, the localStorage is cleared. hence why when we
    // go back to the dashboard, theres a lot of errors message since
    // we dont have the token in our localStorage anymore
    // localStorage.clear();
  }, []);

  const onFinish = (values) => {
    const payload = {
      token: searchParams.get("token"),
      password: values.password,
    };
    changePassword(payload)
      .then((res) => {
        if (res) {
          notification.success({
            message: "Success!",
            description: "Successfully changed the password!",
            placement: "topRight",
          });
          navigate("/", { state: { prevPath: location.pathname } });
        }
      })
      .catch((err) => {
        // console.log(err.response.data.stat_msg);
        if (err?.response?.data?.stat_msg === "Token not valid") {
          notification.error({
            message: "Failed!",
            description: "Invalid token!",
            placement: "topRight",
          });
        } else {
          notification.error({
            message: "Failed!",
            description: "Failed to change password!",
            placement: "topRight",
          });
        }
        throw new Error(err);
      });
  };

  const handleChangeField = (e) => {
    if (e.target.id === "password") {
      const password = e.target.value;
      setValidateLength(password.length >= 8);
      setValidateUpperLower(/[a-z]/.test(password) && /[A-Z]/.test(password));
      setValidateNumber(/[0-9]/.test(password));
      setValidateSymbol(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password));
    }
  };

  return (
    <>
      <div className="flex justify-center items-center h-full bg-white">
        <div className="card shadow-2xl bg-white rounded-lg p-16 w-[450px]">
          <h3 className="text-2xl text-gray-900 font-semibold pt-2 pb-1 mb-4">
            Reset Password
          </h3>
          <p className="text-md font-light text-gray-500 mb-8">
            Enter your email and we’ll send the details on how to reset your
            password.
          </p>
          <Form
            form={form}
            onFinish={onFinish}
            onChange={handleChangeField}
            layout="vertical"
            validateMessages={validateMessage}
          >
            <Form.Item
              name="password"
              label={
                <span>
                  New Password <span className="text-red-500">*</span>
                </span>
              }
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input.Password
                placeholder="Type a new password"
                ref={passwordRef}
              />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              label={
                <span>
                  Confirm Password <span className="text-red-500">*</span>
                </span>
              }
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("The two passwords do not match")
                    );
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirm your new password" />
            </Form.Item>
            <div className="mb-4 border-b-gray-200 border-2 rounded-lg p-4">
              <p className="text-sm font-semibold text-gray-500 mb-2">
                Your password must:
              </p>
              <div
                className={`${
                  validateLength ? "text-green" : "text-secondary"
                } text-sm flex items-center gap-2 mb-3`}
              >
                <span>Be at least 8 characters long</span>
              </div>
              <div
                className={`${
                  validateUpperLower ? "text-green" : "text-secondary"
                } text-sm flex items-center gap-2 mb-3`}
              >
                <span>Contain both uppercase and lowercase letters</span>
              </div>
              <div
                className={`${
                  validateNumber ? "text-green" : "text-secondary"
                } text-sm flex items-center gap-2 mb-3`}
              >
                <span>Contain at least one number</span>
              </div>
              <div
                className={`${
                  validateSymbol ? "text-green" : "text-secondary"
                } text-sm flex items-center gap-2 mb-3`}
              >
                <span>Contain at least one symbol (such as !, @, #, $)</span>
              </div>
            </div>
            <Form.Item shouldUpdate>
              {() => (
                <Buttons
                  type="primary"
                  htmlType="submit"
                  customclass="w-full"
                  disabled={
                    !form.isFieldsTouched(true) ||
                    !!form
                      .getFieldsError()
                      .filter(({ errors }) => errors.length).length ||
                    !(
                      validateLength &&
                      validateUpperLower &&
                      validateNumber &&
                      validateSymbol
                    )
                  }
                >
                  Update Password
                </Buttons>
              )}
            </Form.Item>
          </Form>
          <div
            className="flex items-center cursor-pointer mt-3"
            onClick={() => navigate(-1)}
          >
            <ChevronLeft className="text-primary" />
            <span className="text-primary ml-3 hover:underline">Go back</span>
          </div>
        </div>
      </div>
    </>
  );
}
