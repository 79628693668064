import { useState, Fragment, useEffect } from "react";
import {
  Buttons,
  CustomPagination,
  InputSearch,
} from "../../../components/atoms";
import { Table, Modal, notification, Button } from "antd";
import { Link } from "react-router-dom";
import {
  PlusOutlined,
  EyeFilled,
  EditFilled,
  DeleteFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import _ from "lodash";
import { BiDownload } from "react-icons/bi";
import { saveAs } from "file-saver";
import moment from "moment";
import { getContactUs, deleteContactsUs } from "../../../services/api";
import FormSection from "./FormSection";

const { confirm } = Modal;
export default function ContactUs() {
  const [section, setSection] = useState("default");
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalData, setTotal] = useState(0);
  const [childData, setChildData] = useState({});
  const [trigger, setTrigger] = useState(false);
  const [params, setParams] = useState({
    offset: 0,
    limit: 10,
    order: "id,desc",
    keyword: "",
  });

  const username = "ceosuiteadmin";
  const password = "c304dm1n0nly";
  const basicAuth = `Basic ${btoa(`${username}:${password}`)}`;

  useEffect(() => {
    if (section === "default") {
      setChildData({});
    }
  }, [section]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, params]);

  const getData = () => {
    setLoading(true);
    getContactUs(params)
      .then((res) => {
        setData(res.data.data);
        setTotal(res.data.total_data);
      })
      .catch((err) => {
        throw new Error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSearch = _.debounce((e) => {
    setParams((prevParams) => ({
      ...prevParams,
      keyword: e.target.value,
      offset: 0,
    }));
  }, 500);

  useEffect(() => {
    if (section === "default") {
      setChildData({});
      getData();
    }
  }, [section]);

  function onPageChange(page, pageSize) {
    const offset = (page - 1) * pageSize;
    const limit = pageSize;
    setParams({ ...params, offset, limit });
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (name) => {
        return <div>{name || "-"}</div>;
      },
    },
    {
      title: "WhatsApp",
      dataIndex: "whatsapp",
      render: (whatsapp) => {
        return <div>{whatsapp || "-"}</div>;
      },
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      render: (phone_number) => <div>{phone_number || "-"}</div>,
    },
    {
      title: "Action",
      dataIndex: "action",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <div className="flex items-center gap-x-1">
          <Buttons
            className="py-1 rounded-lg"
            icon={<EyeFilled className="text-gray-600 hover:text-primary" />}
            onClick={() => viewData(record)}
          />
          <Buttons
            className="py-1 rounded-lg"
            icon={<EditFilled className="text-gray-600 hover:text-primary" />}
            onClick={() => editData(record)}
          />
          <Buttons
            className="py-1 rounded-lg"
            icon={<DeleteFilled className="text-gray-600 hover:text-primary" />}
            onClick={() => deleteData(record.id)}
          />
        </div>
      ),
    },
  ];

  const editData = (record) => {
    setChildData(record);
    setSection("edit");
  };

  const viewData = (record) => {
    setChildData(record);
    setSection("view");
  };

  const addData = () => {
    setSection("add");
  };

  const deleteData = (id) => {
    confirm({
      title: "Confirm Delete",
      content:
        "Are you sure you want to delete this data? This action cannot be undone.",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        return new Promise((resolve, reject) => {
          deleteContactsUs(id)
            .then((res) => {
              notification.success({
                message: "Success!",
                description: "Successfully delete data",
                placement: "topRight",
              });
              getData();
              resolve(res);
            })
            .catch((err) => {
              notification.error({
                message: "Failed!",
                description: "Failed to delete data",
                placement: "topRight",
              });
              reject(err);
            });
        });
      },
    });
  };

  const handleDownloadData = async () => {
    const token = localStorage.getItem("token");
    setLoadingDownload(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/information-contact/report`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Auth-Special-CeoSuite": basicAuth,
          },
        }
      );

      const blob = await response.blob();
      saveAs(blob, `REPORT_${moment().format("l")}.xlsx`);
    } catch (error) {
      notification.error({
        message: "Failed!",
        description: "Failed to download file.",
      });
    } finally {
      setLoadingDownload(false);
    }
  };

  return (
    <>
      {section === "default" ? (
        <Fragment>
          <div className="content-section">
            <div className="breadcrumb mb-4 text-md">
              <Link to="/" className="text-gray-500">
                Dashboard
              </Link>
              <span className="mx-2">&gt;</span>
              <span>Add Ons List</span>
            </div>
            <div className="header">
              <h2 className="lg:text-[28px] text-[20px] font-bold">
                Contact Us
              </h2>
              <div className="flex flex-col md:flex-row items-center md:gap-4 gap-1">
                <button
                  className={`flex items-center justify-center px-4 py-2 rounded-md bg-[#F9E7E7] text-primary hover:bg-[#F0D0D0] transition-colors ${
                    loadingDownload ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  onClick={handleDownloadData}
                  disabled={loadingDownload}
                >
                  {loadingDownload ? (
                    <span className="mr-2 animate-spin">&#9696;</span>
                  ) : (
                    <BiDownload className="mr-2" />
                  )}
                  Download Data
                </button>
                <Buttons
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => addData()}
                >
                  Add Contact
                </Buttons>
              </div>
            </div>
            <div className="mb-6 md:w-80 w-32">
              <InputSearch onChange={onSearch} placeholder="Search Contact" />
            </div>
            <div className="body">
              <div className="w-full overflow-x-auto">
                <Table
                  dataSource={data}
                  columns={columns}
                  rowKey="id"
                  loading={loading}
                  pagination={{
                    current: params.page,
                    pageSize: params.page_size,
                    total: totalData,
                    onChange: onPageChange,
                    showTotal: (total, range) => (
                      <div>
                        Showing <strong>{range[1]}</strong> of{" "}
                        <strong>{total}</strong> entries
                      </div>
                    ),
                    className: "ant-table-pagination-custom",
                    itemRender: (_, type, originalElement) => {
                      if (type === "prev") {
                        return <Button type="text">&lt;</Button>;
                      }
                      if (type === "next") {
                        return <Button type="text">&gt;</Button>;
                      }
                      return originalElement;
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </Fragment>
      ) : (
        <FormSection
          setSection={setSection}
          section={section}
          setTrigger={setTrigger}
          trigger={trigger}
          childData={childData}
        />
      )}
    </>
  );
}
