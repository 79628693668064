import { Table } from "antd";
import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";
import { BiDownload } from "react-icons/bi";
import { Buttons } from "../../components/atoms/index";
import { getCustomerDetail, getListCustomers } from "../../services/api";
import CustomerListForm from "./CustomerListForm";
import { EyeFilled } from "@ant-design/icons";
export default function Classification() {
  const [section, setSection] = useState("default");
  const [trigger, setTrigger] = useState(false);
  const [data, setData] = useState([]);
  const [childData, setChildData] = useState({});
  const [totalData, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({
    offset: 0,
    limit: 10,
    order: "id",
    keyword: "",
  });

  const username = "ceosuiteadmin";
  const password = "c304dm1n0nly";
  const basicAuth = `Basic ${btoa(`${username}:${password}`)}`;

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  useEffect(() => {
    getData();
  }, [params]);

  const getData = async () => {
    setLoading(true);
    try {
      const response = await getListCustomers(params);
      const customerData = response?.data?.data?.result;

      if (Array.isArray(customerData) && customerData.length > 0) {
        setData(customerData);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error("Error fetching customers:", error);
      setData([]);
    } finally {
      setLoading(false);
    }
  };

  function onPageChange(page, pageSize) {
    const offset = (page - 1) * pageSize;
    const limit = pageSize;
    setParams({ ...params, offset, limit });
  }

  const handleSearch = (event) => {
    const { value } = event.target;
    setParams((prev) => ({ ...prev, keyword: value }));
  };

  useEffect(() => {
    if (section === "default") {
      setChildData({});
    }
    return () => {};
  }, [section]);

  const columns = [
    {
      title: "No",
      dataIndex: "key",
      render: (text, object, index) => {
        return (currentPage - 1) * pageSize + index + 1; // Menghitung nomor urut
      },
    },
    {
      title: "Name",
      dataIndex: "user.name",
      render: (text, record) => {
        return record.user.name || "-";
      },

      sorter: (a, b) => {
        const nameA = a.user.name || "-";
        const nameB = b.user.name || "-";
        return nameA.localeCompare(nameB);
      },
    },
    {
      title: "Position",
      dataIndex: "position",
      sorter: (a, b) => (a?.position ?? "-").localeCompare(b?.position ?? "-"),
      render: (text, record) => {
        return record.position
          ? record.position.replace(/\b(Position)\b/g, "").trim()
          : "-";
      },
    },

    {
      title: "Company Name",
      dataIndex: "company_name",
      sorter: (a, b) =>
        (a?.company_name ?? "-").localeCompare(b?.company_name ?? "-"),
      render: (text, record) => {
        // return record.company_name || "-";
        return record.position
          ? record.company_name.replace(/\b(Company)\b/g, "").trim()
          : "-";
      },
    },
    // {
    //   title: "Company Address",
    //   dataIndex: "company_address",
    // },
    {
      title: "Company Email",
      dataIndex: "user.email",
      render: (text, record) => {
        return record.user.email || "-";
      },
    },
    {
      title: "Phone Number",
      dataIndex: ["user", "phone_number"],
      render: (text, record) => record?.user?.phone_number || "-",
    },
    {
      title: "Action",
      dataIndex: "action",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <>
          <div className="flex items-center gap-x-1">
            <Buttons
              className="py-1 rounded-lg hover:border-primary"
              icon={<EyeFilled className="text-gray-600 hover:text-primary" />}
              onClick={() => viewData(record)}
            />
            {/* <Buttons
              className="py-1 rounded-lg hover:border-primary"
              icon={<EditFilled className="text-gray-600 hover:text-primary" />}
              onClick={() => editData(record)}
            />
            <Buttons
              className="py-1 rounded-lg hover:border-primary"
              icon={
                <DeleteFilled className="text-gray-600 hover:text-primary" />
              }
              onClick={() => confirmDeleteData(record.id)}
            /> */}
          </div>
        </>
      ),
    },
  ];

  const viewData = (record) => {
    // console.log(record);
    getCustomerDetail(record.id)
      .then((res) => {
        setChildData(res.data.data);
        setSection("view");
      })
      .catch((err) => {
        console.error(err);
      });
  };
  // const viewData = (record) => {
  //   getCustomerDetail(record.uuid).then((res) => {
  //     setChildData(res.data.data);
  //     setSection("view");
  //   });

  const [loadingDownload, setLoadingDownload] = useState(false);

  // const handleDownloadData = () => {
  //   setLoadingDownload(true);
  //   downloadListCustomer(params)
  //     .then((res) => {
  //       downloadFile(res.data.data);
  //     })
  //     .catch((err) => {
  //       return new Error(err);
  //     })
  //     .finally(() => {
  //       setLoadingDownload(false);
  //     });
  // };

  const handleDownload = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/users/report?user_type=customer`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Auth-Special-CeoSuite": basicAuth,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Download failed");
      }

      const blob = await response.blob();
      const fileName = `CUSTOMER_REPORT_${new Date().toLocaleDateString()}.xlsx`;
      saveAs(blob, fileName);
    } catch (error) {
      console.error("Failed to download file:", error);
    }
  };

  return (
    <>
      {section === "default" && (
        <div className="content-section">
          <div className="header">
            <h2>Customers List</h2>
            <Buttons
              type="success"
              icon={<BiDownload />}
              loading={loadingDownload}
              onClick={handleDownload}
            >
              Download Data
            </Buttons>
          </div>
          <div className="body">
            <div className="flex justify-between items-end mb-2">
              {/* <div className="flex gap-4 mb-2">
                <div>
                  <div className="mb-2">Company Name</div>
                  <InputSearch
                    onChange={onSearch}
                    placeholder="Search Company Name"
                  />
                </div>
              </div> */}
              {/* <div className="mb-2">
                <Buttons
                  type="success"
                  icon={<BiDownload />}
                  loading={loadingDownload}
                  onClick={handleDownloadData}
                >
                  Download Data
                </Buttons>
              </div> */}
            </div>
            <div className="w-full overflow-x-auto">
              <Table
                dataSource={data}
                columns={columns}
                rowKey="id"
                loading={loading}
                pagination={{
                  current: currentPage,
                  pageSize: pageSize,
                  onChange: (page) => {
                    setCurrentPage(page);
                    onPageChange(page, pageSize);
                  },
                }}
              />
            </div>
          </div>
        </div>
      )}
      {section !== "default" && (
        <CustomerListForm
          setSection={setSection}
          section={section}
          setTrigger={setTrigger}
          trigger={trigger}
          childData={childData}
        />
      )}
    </>
  );
}
