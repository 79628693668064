import React, { useState, useEffect } from "react";
import { Table, Modal, notification } from "antd";
import _ from "lodash";
import {
  Buttons,
  CustomPagination,
  Images,
  InputSearch,
  Tags,
} from "../../components/atoms";
import {
  PlusOutlined,
  EyeFilled,
  EditFilled,
  DeleteFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";
import FormSection from "./FormSection/FormSection";
import { formatPromoType, formatPromoTypeColor } from "../../utils/Helper";
import {
  deleteOurServices,
  getOurServices,
  getOurServicesDetail,
} from "../../services/api";
import { Link } from "react-router-dom";

const { confirm } = Modal;

export default function ServiceList() {
  const [section, setSection] = useState("default");
  const [trigger, setTrigger] = useState(false);
  const [data, setData] = useState([]);
  // const [DiscountDetail, setDiscountDetail] = useState([]);
  const [childData, setChildData] = useState({});
  const [totalData, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({
    offset: 0,
    limit: 10,
    order: "created_at,desc",
    keyword: "",
  });

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, params]);

  // useEffect(() => {
  //   getDataDetail(params);
  // }, [params]);

  const getData = () => {
    setLoading(true);
    getOurServices(params)
      .then((res) => {
        const filteredData = res.data.data.filter((item) => {
          const keywordLower = params.keyword.toLowerCase();
          const titleLower = item.title ? item.title.toLowerCase() : "";
          const nameLower = item.name ? item.name.toLowerCase() : "";
          return (
            titleLower.includes(keywordLower) ||
            nameLower.includes(keywordLower)
          );
        });
        setData(filteredData);
        setTotal(filteredData.length);
      })
      .catch((err) => {
        throw new Error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function onPageChange(page, pageSize) {
    const offset = (page - 1) * pageSize;
    const limit = pageSize;
    setParams({ ...params, offset, limit });
  }

  const onSearch = _.debounce(function (e) {
    setParams({ ...params, keyword: e.target.value });
  }, 500);

  useEffect(() => {
    if (section === "default") {
      setChildData({});
    }
  }, [section, setSection]);

  const columns = [
    {
      title: "No",
      dataIndex: "key",
      rowScope: "row",
      render: (text, object, index) => {
        return <div>{index + 1}</div>;
      },
    },
    {
      title: "Title",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => (
        <div className="flex items-center gap-2">
          <div>{text}</div>
        </div>
      ),
    },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   width: 450,
    //   render: (text) => {
    //     const words = text.split(" ");
    //     const firstTenWords = words.slice(0, 10);
    //     const truncatedText = firstTenWords.join(" ");
    //     const finalText =
    //       words.length > 10 ? `${truncatedText}...` : truncatedText;
    //     return <div dangerouslySetInnerHTML={{ __html: finalText }} />;
    //   },
    // },
    {
      title: "Preview",
      dataIndex: "sub_name",
      width: 450,
      render: (text) => {
        // Check if text is defined and is a string
        if (typeof text !== "string") {
          return <div>-</div>; // Return a placeholder if text is undefined or not a string
        }

        const words = text.split(" ");
        const firstTenWords = words.slice(0, 10);
        const truncatedText = firstTenWords.join(" ");
        const finalText =
          words.length > 10 ? `${truncatedText}...` : truncatedText;

        return <div dangerouslySetInnerHTML={{ __html: finalText }} />;
      },
    },
    {
      title: "Image",
      dataIndex: "url_image",
      width: 150,
      render: (text, record) => (
        <div className="flex items-center gap-2">
          <Images src={record.url_image} width={120} height={120} />
        </div>
      ),
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      width: 150,
      sorter: (a, b) => a.created_at.localeCompare(b.created_at),
      render: (text, record) => (
        <div>{moment(record.created_at).format("L")}</div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <>
          <div className="flex items-center gap-x-1">
            <Buttons
              className="py-1 rounded-lg hover:border-primary"
              icon={<EyeFilled className="text-gray-600 hover:text-primary" />}
              onClick={() => viewData(record)}
            />
            <Buttons
              className="py-1 rounded-lg hover:border-primary"
              icon={<EditFilled className="text-gray-600 hover:text-primary" />}
              onClick={() => editData(record)}
            />
            <Buttons
              className="py-1 rounded-lg hover:border-primary"
              icon={
                <DeleteFilled className="text-gray-600 hover:text-primary" />
              }
              onClick={() => deleteData(record.id)}
            />
          </div>
        </>
      ),
    },
  ];

  const editData = (record) => {
    getOurServicesDetail(record.id)
      .then((res) => {
        setChildData(res.data.data);
        setSection("edit");
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

  const viewData = (record) => {
    getOurServicesDetail(record.id)
      .then((res) => {
        console.log(res.data.data);
        setChildData(res.data.data);
        setSection("view");
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

  const addData = () => {
    setSection("add");
  };
  console.log(data);
  const deleteData = (id) => {
    confirm({
      title: "Confirm Delete",
      content:
        "Are you sure you want to delete this data? This action cannot be undone.",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        return new Promise((resolve, reject) => {
          deleteOurServices(id)
            .then((res) => {
              notification.success({
                message: "Success!",
                description: "Successfully delete data",
                placement: "topRight",
              });
              getData();
              resolve(res);
            })
            .catch((err) => {
              notification.error({
                message: "Failed!",
                description: "Failed to delete data",
                placement: "topRight",
              });
              reject(err);
            });
        });
      },
    });
  };

  return (
    <>
      {section === "default" && (
        <div className="content-section">
          <div className="breadcrumb mb-4 text-md">
            <Link to="/" className="text-gray-500">
              Dashboard
            </Link>
            <span className="mx-2">&gt;</span>
            <span>Service List</span>
          </div>
          <div className="header">
            <h2 className="lg:text-[28px] text-[20px]">Service List</h2>
            <div className="flex flex-col md:flex-row items-center md:gap-4 gap-1">
              {/* <button
                className={`flex items-center justify-center px-4 py-2 rounded-md bg-[#F9E7E7] text-primary hover:bg-[#F0D0D0] transition-colors ${
                  loadingDownload ? "opacity-50 cursor-not-allowed" : ""
                }`}
                // onClick={handleDownloadData}
                // disabled={loadingDownload}
              >
                {loadingDownload ? (
                  <span className="mr-2 animate-spin">&#9696;</span>
                ) : (
                  <BiDownload className="mr-2" />
                )}
                Download Data
              </button> */}
              <Buttons
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => addData()}
              >
                Add Data
              </Buttons>
            </div>
            {/* <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: "none" }}
            /> */}
          </div>
          <div className="mb-6 md:w-80 w-32">
            <InputSearch onChange={onSearch} placeholder="Search Service" />
          </div>
          <div className="body">
            <div className="w-full overflow-x-auto">
              <Table
                dataSource={data}
                columns={columns}
                rowKey="id"
                loading={loading}
                pagination={{
                  current: currentPage,
                  pageSize: pageSize,
                  onChange: (page) => setCurrentPage(page),
                }}
              />
            </div>
          </div>
        </div>
      )}
      {section !== "default" && (
        <FormSection
          setSection={setSection}
          section={section}
          setTrigger={setTrigger}
          trigger={trigger}
          childData={childData}
        />
      )}
    </>
  );
}
