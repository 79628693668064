import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Image,
  Input,
  message,
  Modal,
  notification,
  Row,
  Select,
  Switch,
  Upload,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ButtonBack, Buttons } from "../../../../components/atoms/index";
import { postPromotions, putPromotions } from "../../../../services/api";
import { getLabel } from "../../../../utils/validateMessage";
import PromotionsDetail from "./PromotionDetail";
import ReactQuill from "react-quill";

export default function FormSection(props) {
  const { setSection, section, childData } = props;
  const [form] = Form.useForm();
  const [content, setContent] = useState("");

  // list ref
  const AddOnsInformationRef = useRef();
  const AddOnsImageRef = useRef();

  // handle file upload
  const [file, setFile] = useState();
  const [fileBase64, setFileBase64] = useState();
  const [modalPreview, setModalPreview] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (section === "edit") {
      setFile(childData.url_image ? { image: childData.url_image } : null);
      if (childData.url_image) {
        setFileBase64(childData.url_image);
      }

      form.setFieldsValue({
        name: childData.name,
        description: childData.description,
        voucher_code: childData.voucher_code,
        voucher_code_title: childData.voucher_code_title,
        image: childData.url_image,
      });
    }
  }, [section, childData, form]);

  const onFinish = (values) => {
    if (!file && section === "add") {
      notification.error({
        message: "Error",
        description: "You must provide a photo for Promotion!",
      });
      return;
    }

    const formData = new FormData();

    const cleanDescription = values.description.replace(/<[^>]+>/g, "");

    formData.append("name", values.name);
    formData.append("description", values.description);
    formData.append("voucher_code", values.voucher_code);
    formData.append("voucher_code_title", values.voucher_code_title);
    formData.append("image", file.originFileObj || file);

    setIsLoading(true);

    if (section === "edit") {
      putPromotions(childData.id, formData)
        .then(() => {
          notification.success({
            message: "Success",
            description: "Successfully updated promotions!",
          });
          setSection("default");
        })
        .catch((err) => {
          console.error(err);
          notification.error({
            message: "Error",
            description: "Failed to update promotions!",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      postPromotions(formData)
        .then(() => {
          notification.success({
            message: "Success",
            description: "Successfully added promotions!",
          });
          setSection("default");
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return false;
    }

    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must be smaller than 2MB!");
      return false;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      setFileBase64(e.target.result);
    };
    reader.readAsDataURL(file);
    setFile(file);
    return false;
  };

  const handlePreview = () => {
    setModalPreview(true);
  };

  const normFile = (e) => {
    return e?.fileList;
  };

  const handleRemove = () => {
    setFile(null);
    setFileBase64(null);
  };

  return (
    <div className="content-section">
      <div className="header">
        <div className="flex items-center">
          <ButtonBack onClick={() => setSection("default")} />
          <div className="text-3xl font-bold">
            {section === "add" && "Add Promotions"}
            {section === "view" && "Promotions Detail"}
            {section === "edit" && "Edit Promotions"}
          </div>
        </div>
        <div className="breadcrumb mb-4 text-md">
          <Link to="/" className="text-gray-500">
            Dashboard
          </Link>
          <span className="mx-2">&gt;</span>
          <Link
            to=""
            onClick={() => setSection("default")}
            className="text-gray-500"
          >
            Promotions List
          </Link>
          {section !== "default" && (
            <>
              <span className="mx-2">&gt;</span>
              <span>
                {section === "add"
                  ? "Add Promotion"
                  : section === "view"
                  ? "Detail"
                  : "Edit"}
              </span>
            </>
          )}
        </div>
      </div>
      <div className="body max-body-height pt-3 pb-0">
        {section !== "view" ? (
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            validateMessages={getLabel}
          >
            <div className="relative scroll-custom">
              <div ref={AddOnsInformationRef} className="fit-scroll py-2">
                <Row justify="space-between" align="middle">
                  <div className="text-xl font-bold text-black mb-2">
                    Promotions
                  </div>
                  {/* <Form.Item
                    layout="horizontal"
                    label={getLabel("Active", false)}
                    name="status"
                    valuePropName="checked"
                    initialValue={true}
                    rules={[{ required: false }]}
                  >
                    <Switch />
                  </Form.Item> */}
                </Row>
                <Row gutter={24}>
                  <Col span={24}>
                    <Form.Item
                      label={getLabel("Name", true)}
                      name="name"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input placeholder="Enter Title" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24} className="my-4">
                  <Col span={12}>
                    <Form.Item
                      label={getLabel("Voucher Code Title", true)}
                      name="voucher_code_title"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input placeholder="Enter Voucher Code Title" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={getLabel("Voucher Code", true)}
                      name="voucher_code"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input placeholder="Enter Voucher Code" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={24}>
                    <Form.Item
                      label={getLabel("Description", false)}
                      name="description"
                    >
                      <ReactQuill
                        placeholder="Type a description"
                        // value={content}
                        // onChange={setContent}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </div>
            <Divider style={{ borderWidth: "1px" }} />
            <div ref={AddOnsImageRef} className="fit-scroll py-2">
              <Row>
                <Col span={8} className="mr-5">
                  <div className="text-xl font-bold text-black mb-2">
                    Upload Photo
                  </div>
                  <div className="text-md font-regular text-gray-500 mb-6">
                    Only 1 (one) photo can be uploaded.
                  </div>
                  <Form.Item
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    name={"attachment"}
                    extra="Recommended dimensions 800 x 600. 1MB maximum with format PNG, JPEG"
                  >
                    <Upload.Dragger
                      listType="picture-card"
                      onPreview={handlePreview}
                      onRemove={handleRemove}
                      beforeUpload={beforeUpload}
                      maxCount={1}
                      fileList={file ? [file] : []}
                      showUploadList={false}
                      accept="image/jpeg,image/png"
                    >
                      <p className="ant-upload-drag-icon">
                        <UploadOutlined className="text-primary" />
                      </p>
                      <p className="ant-upload-text font-bold text-primary text-md">
                        Drag and Drop
                        <span className="font-medium text text-gray-600">
                          {" "}
                          photos here
                        </span>
                      </p>
                      <p className="ant-upload-hint">PNG, JPG, Max size. 2MB</p>
                      <p className="mt-3 mb-3 text-gray-400">or</p>
                      <Button type="primary">Upload Photo</Button>
                    </Upload.Dragger>
                  </Form.Item>
                </Col>
                {file && (
                  <>
                    <Divider
                      type="vertical"
                      className="h-80"
                      style={{ borderLeft: "1px solid #ccc" }}
                    />
                    <Col span={8} className="ml-5">
                      <div className="text-xl font-bold text-black mb-2">
                        Uploaded Photo (1)
                      </div>
                      {fileBase64 ? (
                        <Image
                          className="w-60 rounded-lg object-cover cursor-pointer"
                          src={fileBase64}
                          alt="uploaded"
                        />
                      ) : (
                        <div className="text-red-500">
                          Failed to load image preview
                        </div>
                      )}
                      <Modal
                        open={modalPreview}
                        footer={null}
                        onCancel={() => setModalPreview(false)}
                      >
                        {fileBase64 && (
                          <img
                            style={{ width: "100%" }}
                            src={fileBase64}
                            alt="Preview"
                          />
                        )}
                      </Modal>
                    </Col>
                  </>
                )}
              </Row>
              <div className="border-t-2 -mx-5 mt-12">
                <div className="flex justify-end gap-3 px-5 pt-4">
                  <Buttons
                    type={"default"}
                    onClick={() => setSection("default")}
                  >
                    Cancel
                  </Buttons>
                  <Form.Item type="button">
                    <Buttons
                      type="primary"
                      htmlType="submit"
                      loading={isLoading}
                    >
                      Save
                    </Buttons>
                  </Form.Item>
                </div>
              </div>
            </div>
          </Form>
        ) : (
          <PromotionsDetail
            {...props}
            AddOnsInformationRef={AddOnsInformationRef}
            AddOnsImageRef={AddOnsImageRef}
          />
        )}
      </div>
    </div>
  );
}
