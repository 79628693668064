import React, { useState, useEffect } from "react";
import { Images, Tags } from "../../../../components/atoms/index";
import {
  formatPromoType,
  formatPromoTypeColor,
  formatRupiah,
} from "../../../../utils/Helper";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

export default function PromotionDetail(props) {
  // icon marker
  L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  });

  const { childData, AddOnsInformationRef, AddOnsImageRef, AddOnsPriceRef } =
    props;
  const [listImage, setListImage] = useState([]);

  useEffect(() => {
    getListImage();
  }, []);

  function getListImage() {
    const images = [childData.url_image];
    setListImage(images);
  }

  return (
    <div className="pb-8 pt-2">
      <div>
        <h2
          ref={AddOnsInformationRef}
          className="text-lg font-semibold text-primary mb-2"
        >
          Promotion Detail Information
        </h2>
        <div className="mb-4">
          <div className="text-secondary mb-2">Promotion Picture</div>
          <div className="flex gap-4 mb-4">
            {listImage?.map((item, index) => (
              <Images src={item} key={index} width={150} height={150} />
            ))}
            {listImage?.length < 1 && <div>No picture</div>}
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <div className="text-secondary">Promotion Name</div>
            <div>{childData.name}</div>
          </div>
          <div>
            <div className="text-secondary"></div>
            <div>{childData.category_name}</div>
          </div>
          <div>
            <div className="text-secondary">Voucher Code Title</div>
            <div>{childData.voucher_code_title}</div>
          </div>
          <div>
            <div className="text-secondary">Voucer Code</div>
            <Tags
              title={formatPromoType(childData.voucher_code)}
              color={formatPromoTypeColor(childData.voucher_code)}
            />
          </div>
        </div>
        <h2
          ref={AddOnsImageRef}
          className="mt-6 text-lg font-semibold text-primary mb-2"
        >
          Description
        </h2>
        <div className="grid grid-cols-1 gap-4">
          <div>
            <div dangerouslySetInnerHTML={{ __html: childData.description }} />
          </div>
        </div>
      </div>
    </div>
  );
}
